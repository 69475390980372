
import { closeCircleOutline } from 'ionicons/icons';
import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'MessageBox',
  components: {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
  },
  props: {
    color: {
      type: String,
      required: false,
      default: 'danger',
    },
  },
  setup(props) {
    const msgText = ref('');
    const msgBoxColor = ref(props.color);

    const showMsg = (message: string): void => {
      close();
      msgText.value = message;
    };

    const close = (): void => {
      msgText.value = '';
    };

    const setColor = (color: string): void => {
      msgBoxColor.value = color;
    };

    return {
      msgText,
      showMsg,
      close,
      closeCircleOutline,
      msgBoxColor,
      setColor,
    };
  },
});
