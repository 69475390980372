import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, { disabled: _ctx.isLoading }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ion_spinner, {
            key: 0,
            name: "dots"
          }))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ]),
    _: 3
  }, 8, ["disabled"]))
}