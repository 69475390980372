
import { ref, onMounted, defineComponent, computed, Ref, inject, watch } from 'vue';
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonRow,
  IonCol,
} from '@ionic/vue';
import { Geolocation } from '@capacitor/geolocation';
import Lookup, { Country } from 'country-code-lookup';
import { map, mapOutline, warning, locateOutline } from 'ionicons/icons';
import haversine from 'haversine';

import MessageBox from '@/components/MessageBox.vue';
import LoadingButton from '@/components/LoadingButton.vue';
import GymMap from '@/components/GymMap.vue';
import getGymsByCountry, { LatLong, GymLocation } from '@/common/api/route/getGymsByCountry';
import getReverseGeoLocationCached from '@/common/api/map/getReverseGeolocation';
import AutoComplete from './AutoComplete.vue';

export default defineComponent({
  name: 'GymSelector',
  components: {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonSelect,
    IonSelectOption,
    IonRow,
    IonCol,
    AutoComplete,
    GymMap,
    LoadingButton,
    MessageBox,
  },
  setup(_, { emit }) {
    const countryNameList = ref<Array<Country>>([...Lookup.countries.sort()]);
    const gymLocationList = ref<Array<GymLocation>>([]);
    const selectedCountryIso3 = ref('');
    const selectedGym = ref('');
    const errorMsg: Ref<typeof MessageBox | null> = ref(null);

    const getUserCountry: () => Ref<Country | null> = inject('getUserCountry', () => ref(null));
    const setUserCountry: (country: Country) => void = inject('setUserCountry', () => undefined);
    const getUserGym: () => Ref<string> = inject('getUserGym', () => ref(''));
    const setUserGym: (gym: string) => void = inject('setUserGym', () => undefined);
    const userCountry = getUserCountry();
    const userGym = getUserGym();
    const autoComplete: Ref<typeof AutoComplete | null> = ref(null);
    const locateButton: Ref<typeof LoadingButton | null> = ref(null);

    const userHasSelectedGym = computed(() => selectedGym.value !== '');
    const userHasSelectedCountry = computed(() => selectedCountryIso3.value !== '');

    let userLatLong: LatLong | null = null;

    const msgBox: Ref<typeof MessageBox | null> = ref(null);
    const clickedGymLocation = ref('');

    const viewMap = ref(false);

    onMounted(async () => {
      if (userCountry.value !== null) {
        // On set value, emits @matchedItem which triggers onCountrySelect
        autoComplete.value?.setValue(userCountry.value.country || 'Switzerland');

        // For new user, default is SGP and Zvertigo
        // After usage, the app remembers the new country and gym
        if (userGym.value !== '') {
          selectedGym.value = userGym.value;
        } else {
          try {
            const gyms = await getGymsByCountry('CHE');
            selectedGym.value = gyms[0].gymLocation;  
          } 
          catch (error:any){
            selectedGym.value = '';
          }
        }
        // Simulate being selected in ion-select
        onGymSelect(selectedGym.value);
      } else {
        reset();
      }
    });

    const setNearestGym = () => {
      if (userLatLong) {
        let minDistance = Number.MAX_VALUE;
        let minDistanceGym = null;
        for (const gymLocation of gymLocationList.value) {
          const distanceToUserLocation = haversine(gymLocation.latLong, userLatLong);
          if (distanceToUserLocation < minDistance) {
            minDistance = distanceToUserLocation;
            minDistanceGym = gymLocation;
          }
        }
        if (minDistanceGym) {
          selectedGym.value = minDistanceGym.gymLocation;
          // Simulate being selected in ion-select
          onGymSelect(minDistanceGym.gymLocation);
          msgBox.value?.setColor('medium');
          msgBox.value?.showMsg(`Gym has been set to: ${minDistanceGym.gymName}`);
        } else {
          throw 'No minimum distance gym found!';
        }
      }
    };

    const handleLocateClick = async () => {
      locateButton.value?.setIsLoading(true);
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        userLatLong = {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
        };
        const response = await getReverseGeoLocationCached(
          `${coordinates.coords.longitude},${coordinates.coords.latitude}`,
        );
        const countryName = response.data.features[0].place_name;
        autoComplete.value?.setValue(countryName);
        setNearestGym();
      } catch (error:any) {
        msgBox.value?.setColor('danger');
        msgBox.value?.showMsg('Unable to determine nearest gym!');
        console.error(error);
      } finally {
        locateButton.value?.setIsLoading(false);
      }
    };

    const reset = () => {
      selectedCountryIso3.value = '';
      gymLocationList.value = [];
      selectedGym.value = '';
    };

    const onCountrySelect = async (country: Country) => {
      errorMsg.value?.close();
      if (country) {
        const countryGymLocations = await getGymsByCountry(country.iso3);
        gymLocationList.value = countryGymLocations;
        selectedCountryIso3.value = country.iso3;
        setUserCountry(country);
      } else {
        reset();
        emit('onCountryReset', true);
      }
    };

    watch(clickedGymLocation, () => {
      selectedGym.value = clickedGymLocation.value;
      // Simulate being selected in ion-select
      onGymSelect(clickedGymLocation.value);
    });

    /**
     * Whenever a gym is selected, emit the location of selected gym
     */
    const onGymSelect = (gymLocation: string) => {
      errorMsg.value?.close();
      setUserGym(gymLocation);
      emit('onGymSelect', gymLocation);
    };

    const onClickViewMap = () => {
      viewMap.value = !viewMap.value;
    };

    return {
      countryNameList,
      onCountrySelect,
      selectedGym,
      onGymSelect,
      gymLocationList,
      userHasSelectedGym,
      userHasSelectedCountry,
      errorMsg,
      viewMap,
      onClickViewMap,
      map,
      mapOutline,
      warning,
      autoComplete,
      locateOutline,
      handleLocateClick,
      locateButton,
      clickedGymLocation,
      msgBox,
    };
  },
});
