
import { computed, defineComponent, inject, Ref, ref } from 'vue';
import { heart, heartOutline } from 'ionicons/icons';
import { IonIcon, toastController } from '@ionic/vue';
import axios from 'axios';
import { throttle } from 'lodash';

export default defineComponent({
  name: 'VoteButton',
  components: {
    IonIcon,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    voteCount: {
      type: Number,
      required: true,
    },
    hasVoted: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const getAccessToken: () => Ref<string> = inject('getAccessToken', () => ref(''));
    const getLoggedIn: () => Ref<boolean> = inject('getLoggedIn', () => ref(false));
    const voteCountRef = computed({
      get: () => props.voteCount,
      set: (value) => emit('update:voteCount', value),
    });
    const hasVotedRef = computed({
      get: () => props.hasVoted,
      set: (value) => emit('update:hasVoted', value),
    });

    let waitingForResponse = false;

    const handleVoteClick = throttle(async (username: string, createdAt: string) => {
      if (!getLoggedIn().value) {
        const toast = await toastController.create({
          header: 'Please log in!',
          position: 'bottom',
          color: 'danger',
          duration: 2000,
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
            },
          ],
        });
        toast.present();
        return;
      }
      if (waitingForResponse) {
        return;
      }
      // Toggle voteCount and hasVoted
      voteCountRef.value += hasVotedRef.value ? -1 : 1;
      hasVotedRef.value = !hasVotedRef.value;

      waitingForResponse = true;
      try {
        const response = await axios.get(
          process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/routes/details/toggleUpVotes',
          {
            params: {username, createdAt},
            headers: {
              Authorization: `Bearer ${getAccessToken().value}`,
            },
          },
        );
        // If different message received, throw error to jump to catch block
        if (response.data.Message !== 'Toggle upvote route success') {
          throw Error(response.data.Message);
        }
      } catch (error:any) {
        console.error(error);
        // Toggle back voteCount and hasVoted if voting failed
        voteCountRef.value += hasVotedRef.value ? -1 : 1;
        hasVotedRef.value = !hasVotedRef.value;
      } finally {
        waitingForResponse = false;
      }
    }, 500);

    return {
      heart,
      heartOutline,
      handleVoteClick,
      voteCountRef,
      hasVotedRef,
    };
  },
});
