import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.msgText.length !== 0)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        class: "message",
        color: _ctx.msgBoxColor
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.msgText), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_button, {
            fill: "clear",
            color: "light",
            shape: "round",
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.closeCircleOutline,
                class: _normalizeClass(_ctx.msgBoxColor === 'danger' ? 'light-color' : '')
              }, null, 8, ["icon", "class"])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["color"]))
    : _createCommentVNode("", true)
}