import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, { class: "global-absolute-position" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_input, {
          class: "ion-text-end",
          modelValue: _ctx.userInput,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userInput) = $event)),
          onKeyup: _withKeys(_ctx.onEnter, ["enter"]),
          onKeydown: _withKeys(_ctx.onEnter, ["tab"])
        }, null, 8, ["modelValue", "onKeyup", "onKeydown"])
      ]),
      _: 1
    }),
    (_ctx.filteredSuggestions.length && _ctx.showList)
      ? (_openBlock(), _createBlock(_component_ion_list, {
          key: 0,
          class: "ion-no-padding"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSuggestions, (s, i) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                button: "",
                class: "ion-no-margin",
                key: i,
                onClick: ($event: any) => (_ctx.onSelected(s))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(s[_ctx.optionsKey]), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}