import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_LoginButton = _resolveComponent("LoginButton")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, { translucent: true }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { class: "smaller-height" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_img, {
            class: "logo",
            onClick: _ctx.handleLogoClick,
            src: _ctx.logoImageSrc
          }, null, 8, ["onClick", "src"]),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_LoginButton)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}