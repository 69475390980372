import axios from 'axios';
import Providers from '@/providers';
import cacheManager from 'cache-manager';

const memoryCache = cacheManager.caching({
  store: 'memory',
  max: 10, // Number of items in cache
  ttl: 1, // Seconds
});
// Set as 1 second to reduce duplicate calls by vue
// Must be small or will be unresponsive to upvotes

const getRoutesByGymUrl = process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/routes';

const getRoutesByGym = async (gymLocation: string): Promise<ResponseData> => {
  const headers = {
    Authorization: `Bearer ${Providers.getAccessToken().value}`,
    accept: 'application/json'
  }
  
  const response = await axios.get(getRoutesByGymUrl, {
    headers,
    params: { 'gym.gymLocation':gymLocation },
  });
  return {
    Message: Array.isArray(response.data) ? 'Query routes by gym success' : 'Something went wrong'
    , Items: response.data
  } as ResponseData;
};

// Caches in-memory, disappears on page refresh
const getRoutesByGymCached = async (gymLocation: string): Promise<ResponseData> => {
  return memoryCache.wrap(gymLocation, function () {
    return getRoutesByGym(gymLocation);
  });
};

interface ResponseData {
  Message: string;
  Items: GymRoute[];
}

interface GymRoute {
  id: number;
  commentCount: number;
  createdAt: string;
  gymLocation: string;
  publicGrade: number;
  routeName: string;
  username: string;
  voteCount: number;
  hasVoted: boolean;
}

export default getRoutesByGymCached;
export { GymRoute };
