import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "vote-button-component",
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.handleVoteClick(_ctx.username, _ctx.createdAt), ["stop","prevent"]))
  }, [
    _createVNode(_component_ion_icon, {
      color: "danger",
      icon: _ctx.hasVotedRef ? _ctx.heart : _ctx.heartOutline
    }, null, 8, ["icon"]),
    _createElementVNode("p", null, _toDisplayString(_ctx.voteCountRef), 1)
  ]))
}