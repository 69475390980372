export interface FootholdType {
    id:number;
    name: string;
}

export function getFootholdTypes():  FootholdType[] {
    return [
        {id:1, name: 'all/selected'},
        {id:2, name: 'mains/pieds'},
        {id:3, name: 'chips'}
    ]
}

export function toFootholdName(id:number): string {
    if(id<1)return 'all/selected';
    return getFootholdTypes().filter(value => value.id === id)[0].name;
}