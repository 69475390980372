
import { IonButtons, IonHeader, IonImg, IonToolbar } from '@ionic/vue';
import LoginButton from './LoginButton.vue';
import { defineComponent, Ref, ref, inject, computed } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Header',
  components: {
    IonButtons,
    IonHeader,
    IonImg,
    IonToolbar,
    LoginButton,
  },
  setup() {
    const router = useRouter();
    const getPrefersDarkMode: () => Ref<boolean> = inject('getPrefersDarkMode', () => ref(false));
    const prefersDarkMode = getPrefersDarkMode();
    const logoImageSrc = computed(() => {
      if (prefersDarkMode.value) {
        return process.env.BASE_URL + 'assets/icons/favicon-darkmode-name.svg';
      } else {
        return process.env.BASE_URL + 'assets/icons/favicon-lightmode-name.svg';
      }
    });

    const handleLogoClick = () => {
      router.push({ name: 'Explore' });
    };

    return {
      logoImageSrc,
      handleLogoClick,
    };
  },
});
