import providers from "@/providers";
import axios from "axios";

export function flatOutRoute(item:any):any {
  return {...item, ...{routeURL: item.routeImage.filePath, countryCode: item.gym.countryCode, gymLocation: item.gym.gymLocation, gymName: item.gym.gymName}};
}

export function flatOutWall(item:any):any {
  return {...item, ...{imageURL: item.image.filePath, gymId: item.gym.id}};
}

export const convertFileToBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export function checkAgainstUnauthorizedResponse(error:any):void {
  if(error.response && error.response.status === 401){
    providers.forceLogout();
  }
}

export async function uploadMedia(image:string, showErrorMsg:(errorMsg:string)=>void):Promise<number|null> {
  const blob = await (await fetch(image)).blob();
  if (!blob.type.startsWith('image/')) {
    showErrorMsg('Das generierte Bild ist ungültig, es sollte vom type image/* sein, es ist aber ' + blob.type);
    console.log('blob:', blob);
    console.log('image:', image);
    return null;
  }
  
  try {
    const formData = new FormData();
    formData.append('file', blob);
    const fileResponse = await axios.post(
        process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/media',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${providers.getAccessToken().value}`,
          },
        },
    );
    return fileResponse.data.id;
  }
  catch (error:any){
    checkAgainstUnauthorizedResponse(error);
    showErrorMsg('Unable to upload image, please try again!');
    console.log(error);
  }
  
  return null;
}