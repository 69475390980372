
import { personCircleOutline } from 'ionicons/icons';
import { IonButton, IonIcon, IonItem, IonList, IonPopover, toastController } from '@ionic/vue';
import { defineComponent, inject, ref, Ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LoginButton',
  components: {
    IonButton,
    IonIcon,
    IonItem,
    IonList,
    IonPopover,
  },
  setup() {
    const router = useRouter();
    const forceLogout: () => Promise<void> = inject('forceLogout', () => Promise.resolve());
    const getLoggedIn: () => Ref<boolean> = inject('getLoggedIn', () => ref(false));
    const getUsername: () => Ref<string> = inject('getUsername', () => ref(''));
    const isLoggedIn = getLoggedIn();

    const popoverEvent = ref();
    const isPopoverOpen = ref(false);

    const setPopoverOpen = (state: boolean, event?: Event) => {
      popoverEvent.value = event;
      isPopoverOpen.value = state;
    };

    const clickLogoutButton = async () => {
      setPopoverOpen(false);
      await forceLogout();

      const toast = await toastController.create({
        header: 'Logged out successfully',
        position: 'bottom',
        color: 'success',
        duration: 3000,
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
          },
        ],
      });
      toast.present();
    };

    const clickProfileButton = () => {
      setPopoverOpen(false);
      router.push({ name: 'Profile' });
    };

    const clickMyRoutesButton = () => {
      setPopoverOpen(false);
      router.push({ name: 'UserRoutes', params: { username: getUsername().value } });
    };

    return {
      isLoggedIn,
      personCircleOutline,
      popoverEvent,
      isPopoverOpen,
      setPopoverOpen,
      clickLogoutButton,
      clickProfileButton,
      clickMyRoutesButton,
    };
  },
});
