import axios from 'axios';
import cacheManager from 'cache-manager';
import Providers from "@/providers";
import {checkAgainstUnauthorizedResponse} from "@/common/utils";

const memoryCache = cacheManager.caching({
  store: 'memory',
  max: 10, // Number of items in cache
  ttl: 30, // Seconds
});

const getGymListEndPoint = process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/gyms';

const getGymsByCountry = async (countryCode: string): Promise<GymLocation[]> => {
  try {
    const headers = {
      Authorization: `Bearer ${Providers.getAccessToken().value}`,
      accept: 'application/json'
    }
    const {data } = await axios.get(getGymListEndPoint, {
      headers,
      params: { countryCode },
    });
    
    // Sort alphabetically by gym name
    (data as GymLocation[]).sort((a, b) => (a.gymName > b.gymName ? 1 : -1));
    // Add in longitude and latitude object
    for (const gymLocation of data) {
      const [latitude, longitude] = gymLocation.gymLocation.split(',').map(parseFloat);
      gymLocation.latLong = { latitude, longitude };
    }
    return data;
  } catch (error:any) {
    checkAgainstUnauthorizedResponse(error);
    console.error(error);
  }
  return [];
};

// Caches in-memory, disappears on page refresh
const getGymsByCountryCached = async (countryCode: string): Promise<GymLocation[]> => {
  return memoryCache.wrap('country_' + countryCode, function () {
    return getGymsByCountry(countryCode);
  });
};

interface LatLong {
  latitude: number;
  longitude: number;
}

interface GymLocation {
  id:number
  countryCode: string;
  latLong: LatLong;
  gymLocation: string;
  gymName: string;
}

export default getGymsByCountryCached;
export { LatLong, GymLocation };
