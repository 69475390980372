
import { IonContent, IonPage, IonRow, IonCol, IonButton, IonIcon } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed, Ref, ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { map, mapOutline, shareSocialOutline } from 'ionicons/icons';

import GymSelector from '@/components/gym-selector/GymSelector.vue';
import GymMap from '@/components/GymMap.vue';
import GymRouteList from '@/components/GymRouteList.vue';
import { shareSocial } from '@/common/shareSocial';
import { GymLocation } from '@/common/api/route/getGymsByCountry';

export default defineComponent({
  name: 'Explore',
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    GymSelector,
    GymRouteList,
    GymMap,
  },
  setup() {
    /**
     * `/explore` and `/gym/:gymLocation/:gymName` points to this component
     * If gymLocation exists in path parameters, use a different page layout
     */
    const route = useRoute();
    const gymRouteList: Ref<typeof GymRouteList | null> = ref(null);
    const showGymRouteList = ref(false);
    const gymLocation = computed(() => route.params.gymLocation as string);
    const gymName = computed(() => route.params.gymName as string);
    /* This is a dummy location array to feed into our map so that it shows our current gym location */
    const gymLocationArray: ComputedRef<Array<GymLocation>> = computed(() => {
      const coords = gymLocation.value.split(',').map(parseFloat);
      const latLong = {
        latitude: coords[0],
        longitude: coords[1],
      };
      /* No need for country code as our map doesn't require it */
      return [
        {
          id: 0,
          countryCode: '',
          latLong,
          gymLocation: gymLocation.value,
          gymName: gymName.value,
        },
      ];
    });
    const showGymSelector = computed(
      () => gymLocation.value === undefined && gymName.value === undefined,
    );
    const viewMap = ref(false);

    const handleOnGymSelect = (gymLocation: string) => {
      showGymRouteList.value = true;
      gymRouteList.value?.setGymLocation(gymLocation);
    };

    const handleOnCountryReset = () => {
      showGymRouteList.value = false;
    };

    onMounted(() => {
      if (gymLocation.value && gymName.value) {
        handleOnGymSelect(gymLocation.value);
      }
    });

    const onClickViewMap = () => {
      viewMap.value = !viewMap.value;
    };

    const sharePostHandler = async () => {
      // gymName will be valid as post sharing is only allowed at /gym page
      await shareSocial(route, `Explore ${gymName.value}`);
    };

    return {
      gymRouteList,
      showGymRouteList,
      handleOnGymSelect,
      handleOnCountryReset,
      sharePostHandler,
      showGymSelector,
      gymLocation,
      onClickViewMap,
      viewMap,
      map,
      mapOutline,
      shareSocialOutline,
      gymName,
      gymLocationArray,
    };
  },
});
